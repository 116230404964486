import React from "react";
import {AppLink} from './AppLink';

export const Header=()=>{
    return (
        <div className="bg bg-success d-flex align-items-center" style={{height: "10vh"}} >
            <img
                src={require('../assets/logo.png')}
                width={150}
                height={40}
                style={{marginLeft: 20}}
            />
        </div>
    )
}