import React from "react";
import * as Pages from '../Pages';
import { Route, BrowserRouter, Routes, Switch } from "react-router-dom";

export const AppRoute=()=>{
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact><Pages.Home /></Route>
                <Route path="/privacy"><Pages.Privacy /></Route>
            </Switch>
        </BrowserRouter>
    )
}
