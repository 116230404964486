import React, { PureComponent } from 'react';
import * as Component from '../components';

const Home=()=>{
  return (
    <div>
      <Component.Header/>
      <Component.Body/>
      <AppsDetail/>
      <Component.Footer/>
    </div>
  )
}

export default Home;



const AppsDetail=()=>{
  return (
    <div className='row bg bg-light' style={{height: "20vh"}}>
      <div className='col d-flex justify-content-center align-items-center'>
        
        <img
          src={require('../assets/android.png')}
          width={200}
          height={60}
        />
      </div>
      <div className='col d-flex justify-content-center align-items-center'>
        <img
          src={require('../assets/android.png')}
          width={200}
          height={60}
        />
      </div>
    </div>
  )
}