import React from "react";
import { Message } from "../Constant/Message";


export const Body=()=>{
    return (
        <div className="d-flex align-items-center justify-content-center">
          <div className="imgContainer" style={{
            fontSize: 60, 
            alignItems: 'center', 
            justifyContent: 'center', 
            display: 'flex',
            width: "100%"
          }}>
            <div className="mask"></div>
            <div className="box">
            We Offer free delivery for every product
            </div>
          </div>
        </div>
      );
}