import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";



const Footer=()=>{

    const history = useHistory();

    const onPrivacy=()=>{
        history?.push('/privacy')
    }
    return (
        <div>
            <div className="row d-flex" style={{height: "20vh",paddingLeft: "20px", backgroundColor: 'gray'}}>
                <div style={{height: 20}}/>
                <div className="col-2">
                        <b style={{color: "white"}}>
                            Contact us
                        </b>
                    </div>
                <div className="col">
                    <i style={{color: "white"}}>
                        Email - ecanteen@gmail.com
                    </i>
                    <br/>
                    <i style={{color: "white"}}>
                        Mobile - 1800180012
                    </i>
                </div>
                <div className="col">
                    <button onClick={onPrivacy} className="btn btn-primary">Privacy policy</button>
                </div>
                
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{backgroundColor: 'black', height: 50}}>
                <div style={{color: 'white'}}>
                    Ecanteen.shop All right reserved
                </div>
            </div>
        </div>
    )
}


export default Footer;