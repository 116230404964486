export const privacyList = [
    {
        title: "Collection of your information",
        list: [
            "When you browse our platform we do not collect any information, but we collect if you need any service or product from our platform, then we collect the information as below.",
            "We may collect the basic information such as (name , mobile number and email address) to identifying a user. we also use address as (Delivery address), when you create a account in our system."
        ]
    },
    {
        title: "Sharing of personal information",
        list: [
            "We do not share your personal information to any other third party.",
            "We only use the information to process your orders and services."
        ]
    },
    {
        title: "Security Precautions",
        list: [
            "We maintain a safeguard while transmitting your information in encrypted form."
        ]
    },
    {
        title: "Cancellation of orders",
        list: [
            "When you place an order from out platform, then you can only cancel it before final delivery, after product is delivered to you then we can not accept any cancellation of order."
        ]
    },
    {
        title: "Customer support",
        list: [
            "You can reach us by email as estore@gmail.com",
        ]
    }
]