import React, { PureComponent } from 'react';
import {AppRoute} from './src/Route';

const App=()=>{
  return (
    <>
      <AppRoute/>
    </>
  )
}

export default App;