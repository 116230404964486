import React, { Component } from "react";
import { privacyList } from "../Constant/privacy";
import { Header } from "../components";


const Privacy=()=>{
    return (
      <div>
      <Header/>
        <div className="p-4">
          <h1>Privacy Policy</h1>
          <hr/>
          <br/>
          <br/>
          {
            privacyList?.map((item,index)=>{
              return (
                <PrivacyCard
                  key={index}
                  title ={item?.title}
                  list={item.list}
                />
              )
            })
          }
          <i>Last updated on 19-FEB-2024</i>
        </div>
      </div>
    )
}

export default Privacy;



const PrivacyCard=({
  title,
  list=[],
  id
})=>{
  return (
    <div>
      <b>{title}</b>
      <br/>
      {
        list?.map((item,index)=>{
          return (
            <div className="" key={String(index)}>
              {item}
            </div>
          )
        })
      }
      <br/>
    </div>
  )
}